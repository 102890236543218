<template>
  <div role="switch" class="el-switch is-checked" aria-checked="true">
    <input type="checkbox" name="" true-value="1" false-value="2" class="el-switch__input">
    <span class="el-switch__core" style="width: 40px; border-color: rgb(19, 206, 102); background-color: rgb(19, 206, 102);"></span>
  </div>
</template>
<script>
export default {
  created() {
  },
  data() {
    return {}
  }
}
</script>
<style lang="scss">

</style>
