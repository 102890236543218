<template>
  <div role="switch" class="el-switch"><input type="checkbox" name="" true-value="1" false-value="2" class="el-switch__input"><!----><span class="el-switch__core" style="width: 40px; border-color: rgb(255, 73, 73); background-color: rgb(255, 73, 73);"></span><!----></div>
</template>
<script>
export default {
  created() {
  },
  data() {
    return {}
  }
}
</script>
<style lang="scss">

</style>
