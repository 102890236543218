<template>
  <el-card v-loading="loading" class="auth-detail">
    <div class="info">
      <img
          :src="info.logoUrl"
          class="logo"
          alt=""
      >
      <span class="name">{{ info.name }}（{{ info.shortName }}）</span>
      <el-switch
          v-if="type === 'sass'"
          :value="info.status"
          active-color="#13ce66"
          inactive-color="#ff4949"
          :active-value="1"
          :inactive-value="2"
          @change="updateStatus"
      />
    </div>
    <el-descriptions
        :column="3"
        :label-style="{width:'200px'}"
        title="企业基础信息"
        border
    >
      <el-descriptions-item>
        <template slot="label">
          <i class="el-icon-school"/>
          所在行业
        </template>
        {{ info.industry }}
      </el-descriptions-item>
      <el-descriptions-item>
        <template slot="label">
          <i class="el-icon-office-building"/>
          企业规模
        </template>
        {{ scaleOptions[info.scale - 1] }}
      </el-descriptions-item>
      <el-descriptions-item>
        <template slot="label">
          <i class="el-icon-c-scale-to-original"/>
          企业工商码
        </template>
        {{ info.code }}
      </el-descriptions-item>
      <el-descriptions-item>
        <template slot="label">
          <i class="el-icon-postcard"/>
          企业网站
        </template>
        {{ info.website }}
      </el-descriptions-item>
      <el-descriptions-item>
        <template slot="label">
          <i class="el-icon-c-scale-to-original"/>
          邮编
        </template>
        {{ info.zipCode }}
      </el-descriptions-item>
      <el-descriptions-item>
        <template slot="label">
          <i class="el-icon-location-outline"/>
          办公地址
        </template>
        {{ info.address }}
      </el-descriptions-item>
      <el-descriptions-item>
        <template slot="label">
          <i class="el-icon-user"/>
          联系人
        </template>
        {{ info.contactName }}
      </el-descriptions-item>
      <el-descriptions-item>
        <template slot="label">
          <i class="el-icon-phone-outline"/>
          联系电话
        </template>
        {{ info.contactPhone }}
      </el-descriptions-item>
      <el-descriptions-item>
        <template slot="label">
          <i class="el-icon-message"/>
          联系人邮箱
        </template>
        {{ info.contactMail }}
      </el-descriptions-item>
      <el-descriptions-item>
        <template slot="label">
          <i class="el-icon-tickets"/>
          备注
        </template>
        {{ info.remark }}
      </el-descriptions-item>
    </el-descriptions>
    <el-descriptions
        :column="2"
        :label-style="{width:'200px'}"
        title="对接信息"
        style="margin-top: 20px;"
        border
    >
      <el-descriptions-item>
        <template slot="label">
          <i class="el-icon-user"/>
          对接人
        </template>
        {{ info.dockingPerson }}
      </el-descriptions-item>
      <el-descriptions-item>
        <template slot="label">
          <i class="el-icon-phone-outline"/>
          对接人联系方式
        </template>
        {{ info.dockingPhone }}
      </el-descriptions-item>
      <el-descriptions-item>
        <template slot="label">
          <i class="el-icon-tickets"/>
          备注
        </template>
        {{ info.dockingRemark }}
      </el-descriptions-item>
    </el-descriptions>
    <el-descriptions
        :column="2"
        :label-style="{width:'200px'}"
        title="授权信息"
        style="margin-top: 20px;"
        border
    >
      <el-descriptions-item>
        <template
            slot="label"
            style="width:300px"
        >
          <i class="el-icon-user"/>
          授权人
        </template>
        {{ info.dockingPerson }}
      </el-descriptions-item>
      <el-descriptions-item>
        <template slot="label">
          <i class="el-icon-date"/>
          授权时间
        </template>
        {{
          info.authorizationDuration || info.authorizationDuration === 6 ? `${info.authorizationBeginTime}~${info.authorizationEndTime}` : '无限期'
        }}
      </el-descriptions-item>
      <el-descriptions-item v-if="type === 'auto'">
        <template
            slot="label"
            style="width:300px"
        >
          <i class="el-icon-user"/>
          机器码
        </template>
        {{ info.machineCode }}
      </el-descriptions-item>
      <el-descriptions-item
          v-if="type === 'auto'"
          :content-style="{'max-width': '400px'}"
      >
        <template
            slot="label"
        >
          <i class="el-icon-user"/>
          授权码
        </template>
        {{ info.authorizationCode }}
      </el-descriptions-item>
      <el-descriptions-item>
        <template slot="label">
          <i class="el-icon-tickets"/>
          备注
        </template>
        {{ info.authorizationRemark }}
      </el-descriptions-item>
    </el-descriptions>
    <el-descriptions
        :column="2"
        :label-style="{width:'200px'}"
        title="企业系统配置信息"
        style="margin-top: 20px;"
        border
    >
      <el-descriptions-item>
        <template slot="label">
          <i class="el-icon-user"/>
          管理员账号
        </template>
        {{ info.adminAccount }}
      </el-descriptions-item>
      <el-descriptions-item>
        <template slot="label">
          <i class="el-icon-lock"/>
          管理员密码
        </template>
        {{ info.adminPassword }}
      </el-descriptions-item>
      <el-descriptions-item>
        <template slot="label">
          <i class="el-icon-files"/>
          隐私政策模板
        </template>
        <el-tag
            v-for="(tag,index) in info.privacyPolicyTemplateNames"
            :key="index"
            class="mr10"
        >
          {{ tag }}
        </el-tag>
      </el-descriptions-item>
      <el-descriptions-item>
        <template slot="label">
          <i class="el-icon-files"/>
          隐私声明模板
        </template>
        <el-tag
            v-for="(tag,index) in info.privacyStatementTemplateNames"
            :key="index"
            class="mr10"
        >
          {{ tag }}
        </el-tag>
      </el-descriptions-item>
      <el-descriptions-item>
        <template slot="label">
          <i class="el-icon-files"/>
          制度流程模板
        </template>
        <el-tag
            v-for="(tag,index) in info.regimeTemplateNames"
            :key="index"
            class="mr10"
        >
          {{ tag }}
        </el-tag>
      </el-descriptions-item>
      <el-descriptions-item>
        <template slot="label">
          <i class="el-icon-files"/>
          协议模板
        </template>
        <el-tag
            v-for="(tag,index) in info.agreementTemplateNames"
            :key="index"
            class="mr10"
        >
          {{ tag }}
        </el-tag>
      </el-descriptions-item>
      <el-descriptions-item>
        <template slot="label">
          <i class="el-icon-files"/>
          隐私组织模板
        </template>
        <div>{{ info.privacyOrgTemplateName }}</div>
      </el-descriptions-item>
      <el-descriptions-item>
        <template slot="label">
          <i class="el-icon-files"/>
          评估问卷
        </template>
        <el-tag
            v-for="(tag,index) in info.assessTemplateNames"
            :key="index"
            class="mr10"
        >
          {{ tag }}
        </el-tag>
      </el-descriptions-item>
      <el-descriptions-item>
        <template slot="label">
          <i class="el-icon-wallet"/>
          系统资源
        </template>
        <el-tree
            :data="resources"
            :props="{ label: 'name' }"
        />
      </el-descriptions-item>
      <el-descriptions-item>
        <template slot="label">
          <i class="el-icon-wallet"/>
          词典
        </template>
<!--        <el-tree-->
<!--            :data="classOptions"-->
<!--            :props="{ label: 'className' }"-->
<!--        />-->
                <el-cascader-panel
                    ref="elCascader"
                    v-model="info.privacyClassIdList"
                    :disabled="'disabled'"
                    :options="classOptions"
                    style="width: 100%"
                    :show-all-levels="true"
                    :props="{emitPath: false, multiple: true,  value: 'id', label: 'label' }"
                />
      </el-descriptions-item>
    </el-descriptions>
    <div class="dialog-footer text-center mt20">
      <el-button @click="back">
        返 回
      </el-button>
    </div>
  </el-card>
</template>

<script>
import {error, success, treeDataTranslate} from '@core/utils/utils'
import SwitchChedked from '@core/components/switch/SwitchChedked.vue'
import SwitchUnchecked from '@core/components/switch/SwitchUnchecked.vue'
import {
  getSassEnterprise,
  getAutonomyEnterprise, updateSassEnterprisesStatus,
} from '@/api/enterprises/enterprises'
import { ListResource } from '@/api/system/resource/resource'
import { GetClassOptions, GetAllClass } from '@/api/dictLibrary/dictLibrary'
export default {
  data() {
    return {
      info: {},
      loading: true,
      scaleOptions: ['1-100', '101-1000', '1001-10000', '5000~10000', '10000以上'],
      type: '',
      resources: [],
      classOptions: [],
    }
  },
  mounted() {
    this.type = this.$route.query.type
    this.getEnterpriseInfo()
    this.navActiveInit(1, this.type === 'sass' ? 'SASS用户管理' : '独立部署管理', '企业管理')
  },
  destroyed() {
    this.navActiveInit(2, this.type === 'sass' ? 'SASS用户管理' : '独立部署管理', '企业管理', this.type === 'sass' ? '/sassenterprise' : '/enterprises')
  },
  methods: {
    updateStatus() {
      const msgStr = this.info.status === 1 ? '确认停用吗?' : '确认启用吗'
      this.$confirm(msgStr, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        closeOnClickModal: false,
      }).then(() => {
        const submitData = {
          id: this.info.id,
          status: this.info.status === 1 ? 2 : 1,
        }
        updateSassEnterprisesStatus(submitData).then(res => {
          const resData = res.data
          if (resData.code === 0) {
            success(res.data.msg)
            this.getEnterpriseInfo()
          } else {
            error(res.data.msg)
          }
        })
      }).catch(() => {

      })
    },
    back() {
      this.$router.push(this.type === 'sass' ? '/sassenterprise' : '/enterprises')
    },
    getEnterpriseInfo() {
      const apiName = this.type === 'sass' ? getSassEnterprise : getAutonomyEnterprise
      apiName({id: Number(this.$route.query.id)}).then(res => {
        const resData = res.data
        if (resData.code === 0) {
          this.loading = false
          this.info = resData.data
          this.getResources()
          this.getClassOptions()
        }
      })
    },
    getResources() {
      ListResource().then(res => {
        const resData = res.data
        if (resData.code === 0) {
          const resourceList = resData.data.filter(item => this.info.resourceIdList.includes(item.id))
          this.resources = treeDataTranslate(resourceList)
        }
      })
    },
    formatTreeData(data) {
      data.forEach(d => {
        d.disabled = true
        if (d.children.length < 1) {
          d.children = undefined
        } else {
          this.formatTreeData(d.children)
        }
      })
      return data
    },
    getClassOptions(params) {
      GetClassOptions(params).then(res => {
        const resData = res.data
        if (resData.code === 0) {
          console.log(this.info.privacyClassIdList)
          const classList = resData.data.filter(item => this.info.privacyClassIdList.includes(item.id))
          // this.classOptions = classList
          this.classOptions = resData.data
          // this.classOptions = this.formatTreeData(resData.data)
          // console.log(this.classOptions)
        }
      })
    },
  },
}
</script>
<style lang="scss">
.info {
  padding-bottom: 20px;

  .name {
    font-size: 24px;
    padding-right: 20px;
    vertical-align: middle;
  }

  .logo {
    width: 60px;
    height: 60px;
    //position: absolute;
    border-radius: 10px;
  }
}
.auth-detail .el-checkbox{
  pointer-events: none;
}
</style>
